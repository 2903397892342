body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: Sora;
}

html {
  scroll-behavior: smooth;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#TA_cdsscrollingravenarrow363 {
  position: fixed;
  bottom: 0;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

#CDSWIDEXCLOGO {
  height: 40px;
  max-width: 76px;
}
